import React, { useEffect, useState } from "react";
import "../assets/style/careerContent.css";
// import '../assets/style/Responsiveness.css'
import { Link } from "react-router-dom";
import rocket from "../assets/images/rocket.png";
import star1 from "../assets/images/star-1.png";
import star2 from "../assets/images/star-2.png";
import cloud1 from "../assets/images/cloud1.png";
import { FaLongArrowAltRight, FaPenAlt } from "react-icons/fa";
import { BsAndroid2 } from "react-icons/bs";
import { MdDeveloperMode } from "react-icons/md";
import InnerHeader from "./InnerHeader";
import ModalCareer from "./ModalCareer";
import { sendCarrierRequestApi } from "../services";
import { toast } from "react-toastify";
import { JopPostList } from "./Api";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CareerContent = () => {
  const [jobPosts, setJobPosts] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);   


  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetch")
        const data = await JopPostList();
        console.log(data)
        setJobPosts(data.data); 
        console.log("fetch")
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const baseURL = process.env.REACT_APP_BASE_URL;


  return (
    <>
      <InnerHeader />
      <div className="inner-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="inner-text">
                <div className="img">
                  <img src={rocket} alt="Rocket" />
                </div>
                <h5>
                  Current Job Openings
                  <br />
                  <span>
                    <Link to="/"> Home </Link> | Career
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="star">
          <img src={star1} alt="Star 1" />
        </div>
        <div className="star3">
          <img src={star2} alt="Star 2" />
        </div>
        <div className="cloud3">
          <img src={cloud1} alt="Cloud 1" />
        </div>
      </div>

      <div className="inner-career tnb7">
        <div className="container">
          <div className="text-center">
            <div className="small-heading">
              <h4>Featured Jobs</h4>
            </div>
            <div className="big-heading">
              <h5>
                Know your worth and find the <br /> job that qualifies your life
              </h5>
            </div>
          </div>
          <div className="row">
            {jobPosts.map((job, index) => (
              <div className="col-md-6 col-sm-6 col-xs-12" key={index}>
                <div className="inner-career-text shadow   rounded">
                  <div className="career-icon">
              
                           
                  <img
                      src={`${baseURL}/${job.photo}`}
                      alt={`Job Image ${index + 1}`}
                      className="job-icon "
                      style={{
                        
    color: "#10429a",
    
                      }}
                    />

                  </div>
                  <div className="icon-text">
                    <h4>{job.title}</h4> 
                    <p>
                      <span className="fa">
                        <FaLongArrowAltRight style={{ marginRight: "3px" }} />
                      </span>
                      Experience : {job.experience} Years 
                    </p>
                    <p>
                      <span className="fa">
                        <FaLongArrowAltRight style={{ marginRight: "3px" }} />
                      </span>
                      Skill Required : <span>{job.skills}</span> 
                    </p>
                    <ModalCareer /> 
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerContent;